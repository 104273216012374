/* You can add global styles to this file, and also import other style files */

.edit-no-versioning-pop-up[field='comment'] {
  min-width: 300px;
}

.horizontal-form-field {
  display: flex !important;
  flex-direction: row;
}
.horizontal-form-field > kendo-label {
  margin-right: 5px;
}
