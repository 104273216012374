/* Общий файл стилей для всех проектов */

/* arimo-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/arimo/arimo-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/arimo/arimo-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/arimo/arimo-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/arimo/arimo-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/arimo/arimo-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/arimo/arimo-v17-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Arimo') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/roboto/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/roboto/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/roboto/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/roboto/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/roboto/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/roboto/roboto-v29-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-condensed-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/roboto-condensed/roboto-condensed-v19-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/roboto-condensed/roboto-condensed-v19-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/roboto-condensed/roboto-condensed-v19-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/roboto-condensed/roboto-condensed-v19-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/roboto-condensed/roboto-condensed-v19-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/roboto-condensed/roboto-condensed-v19-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

html{
  height: 100%;
}

body{
  margin: 0px;
  font-size: 15px;
  height: 100%;
  font-family: Roboto Condensed;
}

.k-dialog-wrapper{
  z-index: 100000;
  background: rgba(0, 0, 0, 0.37);
}

.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently not supported by any browser */
}

kendo-grid .no-padding {
  padding: 0 !important;
}

.has-cell-wrap{
  padding-top: 1px !important;
  height:100%
}

/* Стили для kendo notification */
.k-notification-group{
  z-index: 200000;
}

.notification-class{
  font-size: 16px;
  z-index: 1000000 !important;
}
/* --------------------------- */


/* Используется для форм в которых кнопки расположены снизу,
а контекнт формы прокручивается по высоте, если ему не хватает места*/
.kendo-custom-form {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.kendo-custom-form > .kendo-custom-form-content {
  flex: 1;
  overflow-y: auto;
}
.kendo-custom-form > .kendo-custom-form-buttons {
  margin-top: 10px;
}

input[type="checkbox"] {
  border-color: #606060;}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.h-100 {
  height: 100%;
}

.flex-1 {
  flex: 1;
}

.overflow-auto {
  overflow: auto;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}